/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { breakpoints, RESERVATION_SKELETON_ROOM_AVAILABILITIES_NUMBER, RESERVATION_SKELETON_ROOM_NUMBER } from "src/app/utils/constants/constants";
import SingleSkeletonRoom from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Skeleton/SingleSkeletonRoom.component";
import SingleSkeletonAvailability from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Skeleton/SingleSkeletonAvailability.component";
import classNames from "classnames";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";

type Props =
	ReturnType<typeof mapStateToProps>

function SkeletonMobile(props: Props) {

	const {
		bodyWidth,
	} = props;

	return (
		<div className="flex flex-col gap-3">
			{
				new Array(RESERVATION_SKELETON_ROOM_NUMBER).fill("").map((_, roomIndex) =>
					<div className="flex flex-col gap-2 overflow-hidden" key={ roomIndex }>
						<SingleSkeletonRoom className="w-full !min-w-0"/>
						<div className={ classNames(
							"gap-1 overflow-x-hidden",
							"hidden",
							"xxs:flex",
						) }>
							{
								bodyWidth > breakpoints.SM &&
								new Array(RESERVATION_SKELETON_ROOM_AVAILABILITIES_NUMBER).fill("").map((_, availabilityIndex) =>
									<SingleSkeletonAvailability key={ availabilityIndex }/>
								)
							}
						</div>
					</div>
				)
			}
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodyWidth: state.ui.layout.bodySize.width,
});

export default connect(mapStateToProps)(SkeletonMobile);