import { Outlet } from "react-router-dom";

function PurchasingResultLayout() {

	return (
		<div>
			<main className="w-full">
				<Outlet context={ {} }/>
			</main>

		</div>
	);
}

export default PurchasingResultLayout;
