import { useFormPrompt } from "src/app/utils/hooks/useFormPrompt";
import { createContext, PropsWithChildren, useState } from "react";

type ProfileCard = {
	id: string
	isEdited: boolean
	// isNestedRoute?: boolean
}

type ContextType = {
	toggleComponentEdited: (profileCardId: string, isEdited: boolean/*, isNestedRoute?: boolean*/) => void
}

export const CallbackPromptContext = createContext<ContextType>(null!);

function CallbackPromptProvider(props: PropsWithChildren) {

	const [ profileCards, setProfileCards ] = useState<ProfileCard[]>([]);

	const _toggleComponentEdited = (profileCardId: string, isEdited: boolean/*, isNestedRoute = false*/) => {
		if (profileCards.some(profileCard => profileCard.id === profileCardId)) {
			setProfileCards(prevState => prevState.map(profileTabCard => {
				if (profileTabCard.id === profileCardId) {
					return { ...profileTabCard, isEdited/*, isNestedRoute*/ };
				}
				return profileTabCard;
			}));
		} else {
			setProfileCards(prevState => [ ...prevState, { id: profileCardId, isEdited/*, isNestedRoute*/ } ]);
		}
	};

	useFormPrompt(profileCards.some(profileCard => profileCard.isEdited), false);
	// useFormPrompt(profileCards.some(profileCard => profileCard.isEdited), profileCards.every(profileCard => profileCard.isNestedRoute));

	return (
		<CallbackPromptContext.Provider value={ { toggleComponentEdited: _toggleComponentEdited } }>
			{ props.children }
		</CallbackPromptContext.Provider>
	);
}

export default CallbackPromptProvider;
