/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Helmet } from "react-helmet";
import { getEnvValue } from "src/app/utils/constants/constants";
import { isEmptyString, isNull } from "src/app/utils/typeguards";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";

type Props = ReturnType<typeof mapStateToProps>;

const MetaPixel = ({ isAdminPanel }: Props) => {

	const id = getEnvValue("REACT_APP_META_PIXEL_ID");

	if(isNull(id) || isEmptyString(id) || isAdminPanel) return null;

	return (
		<Helmet>
			<script key="function" id="facebook-pixel-script">
				{ `
					!function(f,b,e,v,n,t,s)

					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?

						n.callMethod.apply(n,arguments):n.queue.push(arguments)};

						if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';

						n.queue=[];t=b.createElement(e);t.async=!0;

						t.src=v;s=b.getElementsByTagName(e)[0];

						s.parentNode.insertBefore(t,s)}(window, document,'script',

						'https://connect.facebook.net/en_US/fbevents.js');

					fbq('init', '${ id }');
					fbq('track', 'PageView');
				` }
			</script>
			<noscript key="image" id="facebook-pixel-image">
				{ `
					<img
						height="1" 
						width="1" 
						style="display:none"
						src="https://www.facebook.com/tr?id=${ id }&ev=PageView&noscript=1"
					/>
				` }
			</noscript>
		</Helmet>
	);
};

const mapStateToProps = (state: RootState) => ({
	isAdminPanel: state.router.location?.pathname?.startsWith("/panel")
});

export default connect(mapStateToProps)(MetaPixel);