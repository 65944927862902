import { Error } from "src/app/types/api/api.types";
import { errorCodeNameDictionary } from "src/app/utils/constants/dictionaries";
import { Address, Enum, EnumDictionary, Nullable, SelectOption, WeekDay } from "src/app/types/util.types";
import { isNotNull, isNull, isString } from "src/app/utils/typeguards";
import { DataState, FetchPaginatedDataBasicRequestFilters, LoadingState, PaginationStateReducer } from "src/app/types/redux.types";
import { UserPermission } from "src/app/types/api/user.types";
import { TableURLParamsKey } from "src/app/types/ui/table.types";
import moment from "moment/moment";
import { SimpleOrganization } from "src/app/types/api/organization.types";
import { Room, SimpleRoom } from "src/app/types/api/room.types";
import { Product } from "src/app/types/api/product.types";
import { Category } from "src/app/types/api/category.types";
import { Venue } from "src/app/types/api/venue.types";
import { LocaleFromISO } from "src/app/utils/luxon";
import { DateTime } from "luxon";
import { DAY_START_HOUR, isDevelopmentEnvironment } from "src/app/utils/constants/constants";

export const getErrorMessage = (error: Error) => {
	const errorMessage = isString(error.message) ? error.message : (error.message?.message ?? "Unknown error");
	return isDevelopmentEnvironment
		?
		`${ error.httpStatus } (${ errorCodeNameDictionary[ error.codeName ] }): ${ errorMessage }`
		:
		errorMessage;
};

export function round(value: number, places: number = 1) {
	const factor = Math.pow(10, places);
	return Math.round(value * factor) / factor;
}

export const mapEnumToSelectOptions =
	<T extends string | number | symbol>(
		enumName: Enum,
		enumDictionary?: EnumDictionary<T, string>,
	): SelectOption<T>[] =>
		Object
			.keys(enumName)
			.map(key => ({
					value: enumName[ key ],
					label: enumDictionary ? enumDictionary[ enumName[ key ] as T ] : formatEnumValue(enumName[ key ]),
				}),
			);

export const formatEnumValue = (enumValue: string) => {
	const replacedUnderscore = enumValue.replace("_", " ");
	return replacedUnderscore.charAt(0).toUpperCase() + replacedUnderscore.slice(1).toLowerCase();
};

export const formatPermissionName = (permission: UserPermission) => {
	const result = permission.replaceAll(".", " ").replace(/([A-Z])/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
};

// Pagination
export const reverseTranslation = <T extends string | number | symbol>(translatedValues: string[], dictionary: EnumDictionary<T, string>): T[] =>
	translatedValues
		.map(translatedValue => {
			const entry =
				Object.entries<string>(dictionary)
					  .find((entry) => {
						  const value = entry[ 1 ];

						  return value === translatedValue;
					  }) as [ T, string ];

			return entry[ 0 ];
		})
		.filter(isNotNull);

export const isDifferentPaginationOptions = (
	pageSizes: { actualPageSize: number, newPageSize: number },
	searches: { actualSearch: Nullable<string>, newSearch: Nullable<string> },
	sorts: { actualSort: Nullable<string>, newSort: Nullable<string> },
	filters: { actualFilters: FetchPaginatedDataBasicRequestFilters, newFilters: FetchPaginatedDataBasicRequestFilters },
) => (
	pageSizes.actualPageSize !== pageSizes.newPageSize ||
	searches.actualSearch !== searches.newSearch ||
	sorts.actualSort !== sorts.newSort ||
	!isSamePaginationFilters(filters.newFilters, filters.actualFilters)
);

const isSamePaginationFilters = (newFilters: { [ p: string ]: string[] }, actualFilters: { [ p: string ]: string[] }): boolean => {
	const isFiltersDifferent = Object.keys(newFilters).some(filterKey => {
		const newFilterValues = newFilters[ filterKey ] ?? [];
		const actualFilterValues = actualFilters[ filterKey ] ?? [];
		if (newFilterValues.length === 0 && actualFilterValues.length === 0) { // Filters from current key are empty = same
			return false;
		} else if (newFilterValues.length !== actualFilterValues.length) { // Filters from current keys has different length = !same
			return true;
		} else {
			return newFilterValues.some(newFilterValue => !actualFilterValues.includes(newFilterValue)); // Filters from current key has same values, lengthA = lengthB
		}
	});

	return !isFiltersDifferent;
};

export const getBoundaryPageIndexes = (
	statePages: PaginationStateReducer<any>["pages"],
	requestingPageIndex: number,
	options: {
		maxPageIndex: number,
		boundaryPagesOffset?: number
	},
): number[] => {
	const DEFAULT_BOUNDARY_PAGE_OFFSET = 1;
	const BOUNDARY_PAGES_OFFSET = options.boundaryPagesOffset ?? DEFAULT_BOUNDARY_PAGE_OFFSET;
	return new Array((2 * BOUNDARY_PAGES_OFFSET) + 1)
		.fill(0)
		.map((pageIndex, index) => requestingPageIndex - (BOUNDARY_PAGES_OFFSET - index))
		.filter(pageIndex => pageIndex !== requestingPageIndex)
		.filter(pageIndex => pageIndex >= 0 && pageIndex <= options.maxPageIndex)
		.filter(pageIndex => {
			const page = statePages.find(page => page.pageIndex === pageIndex);
			return (
				isNull(page) ||
				(page.data.dataState === DataState.NOT_PRESENT && page.data.loadingState === LoadingState.NOT_LOADING)
			);
		});
};

export const getMaxPageIndex = (
	totalCount: number,
	pageSize: number,
) => {
	// TotalCount / PageSize -> MaxPageIndex
	// 9 / 10 -> 0
	// 10 / 10 -> 0
	// 11 / 10 -> 1
	const maxPageIndex = totalCount / pageSize;
	return Math.floor(maxPageIndex) - (Number.isInteger(maxPageIndex) ? 1 : 0);
};

export const getFiltersFromUrl = (urlFilters: string[], filterValueSeparator: string = TableURLParamsKey.FILTER_SEPARATOR) => {
	const highestIndex = urlFilters.reduce((prev, next) => Math.max(prev, +next.split(filterValueSeparator)[ 0 ]), 0);
	return urlFilters.reduce<string[][]>((prev, next) => {
		const [ index, value ] = next.split(filterValueSeparator);
		return [ ...prev.slice(0, +index), [ ...prev[ +index ], value ], ...prev.slice(+index + 1, prev.length) ];
	}, new Array(highestIndex + 1).fill([]));
};

export const getBrowserInfo = () => {
	let nAgt = navigator.userAgent;
	let browserName = navigator.appName;
	let fullVersion = "" + parseFloat(navigator.appVersion);
	let majorVersion: number, nameOffset, verOffset, ix;

	// In Opera, the true version is after "OPR" or after "Version"
	if ((verOffset = nAgt.indexOf("OPR")) != -1) {
		browserName = "Opera";
		fullVersion = nAgt.substring(verOffset + 4);
		if ((verOffset = nAgt.indexOf("Version")) != -1) {
			fullVersion = nAgt.substring(verOffset + 8);
		}
	}
	// In MS Edge, the true version is after "Edg" in userAgent
	else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
		browserName = "Microsoft Edge";
		fullVersion = nAgt.substring(verOffset + 4);
	}
	// In MSIE, the true version is after "MSIE" in userAgent
	else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
		browserName = "Microsoft Internet Explorer";
		fullVersion = nAgt.substring(verOffset + 5);
	}
	// In Chrome, the true version is after "Chrome"
	else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
		browserName = "Chrome";
		fullVersion = nAgt.substring(verOffset + 7);
	}
	// In Safari, the true version is after "Safari" or after "Version"
	else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
		browserName = "Safari";
		fullVersion = nAgt.substring(verOffset + 7);
		if ((verOffset = nAgt.indexOf("Version")) != -1) {
			fullVersion = nAgt.substring(verOffset + 8);
		}
	}
	// In Firefox, the true version is after "Firefox"
	else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
		browserName = "Firefox";
		fullVersion = nAgt.substring(verOffset + 8);
	}
	// In most other browsers, "name/version" is at the end of userAgent
	else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) <
		(verOffset = nAgt.lastIndexOf("/"))) {
		browserName = nAgt.substring(nameOffset, verOffset);
		fullVersion = nAgt.substring(verOffset + 1);
		if (browserName.toLowerCase() == browserName.toUpperCase()) {
			browserName = navigator.appName;
		}
	}
	// trim the fullVersion string at semicolon/space if present
	if ((ix = fullVersion.indexOf(";")) != -1) {
		fullVersion = fullVersion.substring(0, ix);
	}
	if ((ix = fullVersion.indexOf(" ")) != -1) {
		fullVersion = fullVersion.substring(0, ix);
	}

	majorVersion = parseInt("" + fullVersion, 10);
	if (isNaN(majorVersion)) {
		fullVersion = "" + parseFloat(navigator.appVersion);
		majorVersion = parseInt(navigator.appVersion, 10);
	}

	let OSName = "Unknown OS";
	if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
	if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
	if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
	if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

	return {
		browserName,
		fullVersion,
		majorVersion,
		appName: navigator.appName,
		userAgent: navigator.userAgent,
		OS: OSName,
	};
};

export const getAddressString = (address: Nullable<Address>) => {
	if (isNull(address)) return;
	const { street, houseNumber, flatNumber, postCode, city, country } = address;
	if (isNull(flatNumber)) {
		return `${ street } ${ houseNumber }, ${ postCode } ${ city }, ${ country }`;
	}
	return `${ street } ${ houseNumber }/${ flatNumber }, ${ postCode } ${ city }, ${ country }`;
};

export const getWeekDay = (date: string): WeekDay => {
	const weekDay = moment(date).format("dddd");
	switch (weekDay) {
		case "Monday":
			return WeekDay.MONDAY;
		case "Tuesday":
			return WeekDay.MONDAY;
		case "Wednesday":
			return WeekDay.MONDAY;
		case "Thursday":
			return WeekDay.MONDAY;
		case "Friday":
			return WeekDay.MONDAY;
		case "Saturday":
			return WeekDay.MONDAY;
		default:
			return WeekDay.MONDAY;
	}
};

export const getAvailabilityId = (locationId: number, date: string) => `${ locationId }-${ date }`;

export const getQueryParamValue = (queryString: string, paramName: string): Nullable<string> => {
	if (queryString[ 0 ] === "?") queryString = queryString.substring(1, queryString.length);

	const params = queryString.split("&");

	for (const param of params) {
		const [ key, value ] = param.split("=");

		if (key === paramName && value !== undefined) {
			return value;
		}
	}

	return null;
};

export const getAllOrganizationsFromModel = <T extends { organization: Nullable<SimpleOrganization> }>(modelArr: T[]) => modelArr.reduce<string[]>((prev, current) => {
	if (isNull(current.organization) || prev.includes(current.organization.name)) return prev;
	return [ ...prev, current.organization.name ];
}, []);

export const getAllInnerRoomsFromModel = <T extends { rooms: Nullable<SimpleRoom[]> }>(modelArr: T[]) =>
	Array.from(
		new Set(
			modelArr.reduce<string[]>((prev, { rooms }) => {
				if (isNull(rooms)) return prev;
				return [ ...prev, ...rooms.map(room => room.name) ];
			}, []),
		),
	);

export const getAllProductsFromModel = <T extends { products: Nullable<Product[]> }>(modelArr: T[]) =>
	Array.from(
		new Set(
			modelArr.reduce<string[]>((prev, current) => {
				if (isNull(current.products)) return prev;
				return [ ...prev, ...current.products.map(({ name }) => name) ];
			}, []),
		),
	);

export const getAllCategoriesFromModel = <T extends { categories: Category[] }>(modelArr: T[]) =>
	Array.from(
		new Set(
			modelArr.reduce<string[]>((prev, { categories }) => {
				return [ ...prev, ...categories.map(({ name }) => name) ];
			}, []),
		),
	);

export const getAllRoomsFromModel = <T extends { room: Room }>(modelArr: T[]) => modelArr.reduce<string[]>((prev, { room: { name } }) => {
	if (prev.includes(name)) return prev;
	return [ ...prev, name ];
}, []);

export const getAllLocationsFromModel = <T extends { location: string }>(modelArr: T[]) => modelArr.reduce<string[]>((prev, current) => {
	if (isNull(current.location) || prev.includes(current.location)) return prev;
	return [ ...prev, current.location ];
}, []);

export const getAllVenuesFromModel = <T extends { venue: Nullable<Venue> }>(modelArr: T[]) => modelArr.reduce<string[]>((prev, { venue }) => {
	if (isNull(venue) || prev.includes(venue.name)) return prev;
	return [ ...prev, venue.name ];
}, []);

export const customDataTableSearch = (searchQuery: string, fields: Nullable<string>[]) => {
	searchQuery = searchQuery.toLowerCase();
	return fields.filter(isNotNull).some(field => field.toLowerCase().includes(searchQuery));
};

export function formatPhoneToE164(phone: string) {
	const regexes = [ /-/g, /\(/g, /\)/g, /\s/g ];
	return regexes.reduce((prev, next) => prev.replace(next, ""), phone);
}

export const getGoogleMapsLink = (address: Address & { city: string }) => `http://maps.google.com/?q=${ getAddressString(address) }`;

export const getFormattedHour = (hour: number) => (hour - DAY_START_HOUR < 0) ? (24 + (hour - DAY_START_HOUR)) : (hour - DAY_START_HOUR);

// Function can only receive ISO or HH:mm
export const compareReservationTime = (startTime: Nullable<string>, endTime: Nullable<string>) => {
	if (isNull(startTime) || isNull(endTime)) return false;

	const startDateTime = LocaleFromISO(startTime);
	const endDateTime = LocaleFromISO(endTime);

	// 4:00 - 7:00 -> not-valid
	if (startDateTime.hour < DAY_START_HOUR && endDateTime.hour > DAY_START_HOUR) {
		return false;
	}

	const startDate = DateTime.fromObject({
		year: startDateTime.year,
		month: startDateTime.month,
		day: startDateTime.day,
		hour: getFormattedHour(startDateTime.hour),
		minute: startDateTime.minute,
	});

	const endDate = DateTime.fromObject({
		year: endDateTime.year,
		month: endDateTime.month,
		day: endDateTime.day,
		hour: getFormattedHour(endDateTime.hour),
		minute: endDateTime.minute,
	});

	return startDate.valueOf() < endDate.valueOf();
};

export const handleMetaPixelEvent = (eventType: string) => {
	if (typeof window !== "undefined" && window.fbq) {
		window.fbq("track", eventType);
	}
};