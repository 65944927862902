/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { mapFormToCalculatePricePayload, PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import React, { useEffect, useState } from "react";
import ReservationChooseDate from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/PurchasingProcessChooseDate.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import ReservationSelectVenueHeader from "src/app/components/PurchasingProcess/util/Header/SelectVenueHeader.component";
import { SimpleVenue } from "src/app/types/api/venue.types";
import AvailabilitiesDesktop from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/AvailabilitiesDesktop.component";
import AvailabilitiesMobile from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/AvailabilitiesMobile.component";
import { isNull } from "src/app/utils/typeguards";
import { uiCalculateReservationPrice } from "src/app/store/features/ui/reservation/ui.reservation.actions";
import { Button } from "flowbite-react";
import { Nullable } from "src/app/types/util.types";
import useReservingData from "src/app/utils/hooks/useReservingData";
import { handleMetaPixelEvent } from "src/app/utils/helpers";

type ComponentProps = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	fetchAvailabilities: (locationId: number, date: string) => void
	venues: SimpleVenue[]
	isAtLeastOneAvailabilityChosen: boolean
}

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

function PurchasingProcessChooseAvailabilitiesContainer(props: Props) {

	const form = props.form;

	const {
		form: {
			form: {
				date,
				dateRoomAvailabilities,
				venue,
			},
		},
		isFetchingAvailabilities,
		fetchAvailabilities,
		isFetchingInitialAvailabilities,
		venues,
		isAtLeastOneAvailabilityChosen,
		calculateReservationPrice,
		isCalculatingPrice,
	} = props;

	const reservingData = useReservingData();

	const [ chooseDateHeight, setChooseDateHeight ] = useState<Nullable<number>>(null);
	const [ actualHeaderHeight, setActualHeaderHeight ] = useState<Nullable<number>>(null);
	const [ windowScrollValue, setWindowScrollValue ] = useState(0);

	const headerHeight = 190;

	const showSkeleton = isFetchingInitialAvailabilities || isFetchingAvailabilities || isNull(form.form.venue.value);

	const onStepConfirm = () => {
		const mappedPayload = mapFormToCalculatePricePayload(props.form.form, reservingData);
		if (isNull(mappedPayload)) return;
		handleMetaPixelEvent("InitiateCheckout");
		calculateReservationPrice({
			...mappedPayload,
			maxPeople: reservingData?.room?.maxPeople,
		});
	};

	useEffect(() => {
		document.addEventListener("scroll", () => {
			setWindowScrollValue(window.scrollY);
		});
	}, []);

	const getContent = () => {
		if (isNull(venue.value)) {
			return (
				<span className="text-center text-4xl font-[900] text-myPrimary-purple-500 mt-10">
					Brak lokalizacji
				</span>
			);
		}
		if (venue.value.rooms?.length === 0) {
			return (
				<span className="text-center text-4xl font-[900] text-myPrimary-purple-500 mt-10">
					Brak pokoi
				</span>
			);
		}

		//Impossible to happen but you never know
		if (
			venue.value.rooms?.length === 0 &&
			dateRoomAvailabilities.value.find(dateRoomAvailability => dateRoomAvailability.date === date.value)?.roomAvailabilities.length !== 0
		) {
			return (
				<span className="text-center text-4xl font-[900] text-myPrimary-purple-500 mt-10">
					Brak dostępności
				</span>
			);
		}
		return (
			<div>
				{/* Available on > LG */ }
				<AvailabilitiesDesktop
					form={ form }
					showSkeleton={ showSkeleton }
				/>

				{/* Available on < LG */ }
				<AvailabilitiesMobile
					form={ form }
					showSkeleton={ showSkeleton }
				/>
			</div>
		);
	};

	return (
		<div>
			<div className="flex flex-col items-stretch pb-10">
				<ReservationSelectVenueHeader
					form={ form }
					height={ headerHeight }
					fetchAvailabilities={ fetchAvailabilities }
					venues={ venues }
					setActualHeaderHeight={ setActualHeaderHeight }
					actualHeaderHeight={ actualHeaderHeight ?? 0 }
					windowScrollValue={ windowScrollValue }
					chooseDateHeight={ chooseDateHeight ?? 0 }
				/>
				<div className="flex flex-col items-stretch px-4 relative">
					<ReservationChooseDate
						form={ form }
						fetchAvailabilities={ fetchAvailabilities }
						actualHeaderHeight={ actualHeaderHeight ?? 0 }
						windowScrollValue={ windowScrollValue }
						setChooseDateHeight={ setChooseDateHeight }
					/>

					{ getContent() }
				</div>
			</div>
			{
				isAtLeastOneAvailabilityChosen &&
                <Button
                    className="fixed bottom-5 left-1/2 -translate-x-1/2 px-10 z-10 [&>span]:whitespace-nowrap"
                    isProcessing={ isCalculatingPrice }
                    onClick={ onStepConfirm }
                    size="lg"
                    color="reservation-submit-button"
                >
					<span>Idź dalej</span>
                </Button>
			}
		</div>
	);
}

const mapStateToProps = (state: RootState, { form: { form: { venue, date } } }: ComponentProps) => ({
	isFetchingInitialAvailabilities: didLoadingRecordExist(state, { loadableType: LoadableType.INITIAL_LOAD_FETCH_AVAILABILITIES }),
	isFetchingAvailabilities: didLoadingRecordExist(state, { loadableId: `${ venue.value?.id }-${ date.value }`, loadableType: LoadableType.FETCH_AVAILABILITIES }),
	isCalculatingPrice: didLoadingRecordExist(state, { loadableType: LoadableType.CALCULATE_RESERVATION_PRICE }),
});

const mapDispatchToProps = {
	calculateReservationPrice: uiCalculateReservationPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingProcessChooseAvailabilitiesContainer);
