import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { purchasingProcessFormActions } from "src/app/store/features/form/form.actions";
import PurchasingProcessLayoutInner from "src/app/components/PurchasingProcess/util/PurchasingProcessLayoutInner.component";
import { connect } from "react-redux";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { FormHookReturnType } from "src/app/types/ui/form.types";
import { AvailabilityState, Cart } from "src/app/types/api/reservation.types";
import { useEffect } from "react";
import { LoadableType } from "src/app/types/ui/loading.types";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { getVenuesWithProductsWithCategories } from "src/app/store/features/venue/venue.selectors";
import { RootState } from "src/app/store/root.reducer";
import { SimpleVenue } from "src/app/types/api/venue.types";
import { SimpleProduct } from "src/app/types/api/product.types";
import { uiFetchVenueAvailabilitiesDebounce } from "src/app/store/features/ui/venue/ui.venue.actions";
import { KAKADU_ORG } from "src/app/utils/constants/constants";
import { uiCreateReservation, uiReservationInit } from "src/app/store/features/ui/reservation/ui.reservation.actions";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { GridLoader } from "react-spinners";
import { Category } from "src/app/types/api/category.types";
import useReservingData from "src/app/utils/hooks/useReservingData";
import { isNull } from "src/app/utils/typeguards";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

export type PurchasingProcessOutletContext = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	cart: Cart
	fetchAvailabilities: (venueId: number, date: string) => void
	venues: SimpleVenue[]
	products: SimpleProduct[]
	categories: Category[]
	isAtLeastOneAvailabilityChosen: boolean
}

function PurchasingProcessLayout(props: Props) {

	const {
		fetchAvailabilities,
		venuesWithProductsWithCategories,
		isReservationLoading,
		reservationInit,
		createReservation,
		isFetchingVenues,
		isFetchingProducts,
		isFetchingCategories,
	} = props;

	const reservingData = useReservingData();

	const _handleSubmit = (formValues: PurchasingProcessReducerForm) => {

		if (isNull(reservingData)) return;

		if (!formValues.paymentForm.isForCompany.value) {
			createReservation({
				date: formValues.date,
				roomId: reservingData.room.id,
				startDate: reservingData.startTime,
				endDate: reservingData.endTime,
				people: formValues.paymentForm.people.value,
				name: formValues.paymentForm.name.value,
				surname: formValues.paymentForm.surname.value,
				email: formValues.paymentForm.email.value,
				phone: formValues.paymentForm.phone.value,
				reservingPerson: "",
				comment: "",
				isMarketingConsent: formValues.paymentForm.informAboutPromotions.value,
				discountCode: formValues.paymentForm.discountCode.value,
				products: formValues.products.map(product => {
					return {
						id: product.id,
						quantity: product.count,
					};
				}),
				isForCompany: formValues.paymentForm.isForCompany.value,
			});
		} else {
			createReservation({
				date: formValues.date,
				roomId: reservingData.room.id,
				startDate: reservingData.startTime,
				endDate: reservingData.endTime,
				people: formValues.paymentForm.people.value,
				name: formValues.paymentForm.name.value,
				surname: formValues.paymentForm.surname.value,
				email: formValues.paymentForm.email.value,
				phone: formValues.paymentForm.phone.value,
				reservingPerson: "",
				comment: "",
				isMarketingConsent: formValues.paymentForm.informAboutPromotions.value,
				discountCode: formValues.paymentForm.discountCode.value,
				products: formValues.products.map(product => {
					return {
						id: product.id,
						quantity: product.count,
					};
				}),
				isForCompany: formValues.paymentForm.isForCompany.value,
				companyName: formValues.paymentForm.companyName.value,
				companyNip: formValues.paymentForm.companyNip.value,
				companyAddress: formValues.paymentForm.companyAddress.value,
				companyPostCode: formValues.paymentForm.companyPostCode.value,
				companyCity: formValues.paymentForm.companyCity.value,
			});
		}
	};

	const form = useReducerForm(
		"purchasingProcess",
		purchasingProcessFormActions,
		_handleSubmit,
	);

	const _fetchAvailabilities = (venueId: number, date: string) => {
		fetchAvailabilities({ venueId, date });
	};

	useEffect(() => {
		reservationInit({ organization: { id: KAKADU_ORG, name: "", image: null } });
	}, []);

	const isAtLeastOneAvailabilityChosen: boolean =
		form.form.dateRoomAvailabilities.value.some(dateRoomAvailabilities =>
			dateRoomAvailabilities.roomAvailabilities.some(roomAvailability =>
				roomAvailability.availabilities.some(availability =>
					availability.state === AvailabilityState.RESERVING,
				),
			),
		);

	if (isReservationLoading) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<GridLoader size={ 30 } color="#EC5600"/>
			</div>
		);
	} else if (!(isFetchingVenues && isFetchingProducts && isFetchingCategories)) {
		return (
			<ForceFreshStrategy
				request={ () => undefined } // data is fetching in ui.epic of uiReservationInit action
				state={ venuesWithProductsWithCategories }
			>
				{
					({ venues, products, categories }) =>
						<PurchasingProcessLayoutInner
							form={ form }
							fetchAvailabilities={ _fetchAvailabilities }
							venues={ venues }
							products={ products }
							isAtLeastOneAvailabilityChosen={ isAtLeastOneAvailabilityChosen }
							categories={ categories }
						/>
				}
			</ForceFreshStrategy>
		);
	} else {
		return null;
	}
}

const mapStateToProps = (state: RootState) => ({
	venuesWithProductsWithCategories: getVenuesWithProductsWithCategories(state),
	isReservationLoading: didLoadingRecordExist(state, { loadableType: LoadableType.RESERVATION_INIT }),
	isFetchingVenues: didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_VENUES }),
	isFetchingProducts: didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_PRODUCTS }),
	isFetchingCategories: didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_CATEGORIES }),
});

const mapDispatchToProps = {
	fetchAvailabilities: uiFetchVenueAvailabilitiesDebounce,
	reservationInit: uiReservationInit,
	createReservation: uiCreateReservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingProcessLayout);
