/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Button, Modal } from "flowbite-react";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import { SnackbarMessageType } from "src/app/types/ui/message.types";

type Props = {
	isOpen: boolean
	handleClose: () => void
};

function PurchasingProcessAgeModal(props: Props) {

	const {
		isOpen,
		handleClose,
	} = props;

	return (
		<Modal
			show={ isOpen }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ isOpen ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header
				className="[&_button]:hidden"
			>
				Uwaga!
			</Modal.Header>
			<Modal.Body>
				<div className="space-y-6">
					<SnackbarComponent
						type={ SnackbarMessageType.WARNING }
						message={
							<div>
								<p>Alkohol sprzedawany w naszym lokalu jest dostępny wyłącznie dla osób pełnoletnich (18+).</p>
								<br/>
								<p>W przypadku zamówienia alkoholu, prosimy o przygotowanie dokumentu tożsamości potwierdzającego wiek w momencie przyjścia do lokalu.</p>
								<br/>
								<p>Dziękujemy za zrozumienie i życzymy udanej zabawy!</p>
							</div>
						}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer className="flex justify-end border-none pt-0">
				<Button onClick={ handleClose }>
					Rozumiem
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default (PurchasingProcessAgeModal);
